import PerfectScrollBar from "react-perfect-scrollbar";

import styles from "./style.module.css";
import ListReport from "./components/ListReport";

const Report = () => {
	return (
		<div className={styles.container}>
			<PerfectScrollBar
			// className={styles.tab_content}
			>
				<ListReport />
			</PerfectScrollBar>
		</div>
	);
};

export default Report;
