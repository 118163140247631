import { createContext, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import authApi from "apis/authentication";
import { TOKEN_KEY, EXPIRED_KEY, USERNAME_KEY, Role } from "constants/global";
import Loading from "components/ui/Loading2";
import { showErrorNotification } from "components/ui/Notification";

const authContext = createContext();

export function useAuthProvider() {
  return useContext(authContext);
}

export function AuthProvider({ children }) {
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem(TOKEN_KEY);
    let expired = localStorage.getItem(EXPIRED_KEY);
    let username = localStorage.getItem(USERNAME_KEY);
    let timeout = null;
    let remainingTime = null;

    const getUser = async () => {
      try {
        setLoading(true);
        const data = await authApi.getUser();
        setLoading(false);
        return data;
      } catch (err) {
        logout();
        setLoading(false);
        return null;
      }
    };

    if (currentUser) {
      token = currentUser.token;
      expired = currentUser.expired;
      remainingTime = calcRemaningTime(expired);
    } else if (!currentUser && token && expired && username) {
      expired = parseInt(expired);
      getUser()
        .then((userData) => {
          const isValidToken = userData !== null;
          if (isValidToken) {
            const user = {
              username,
              token,
              expired,
              role: userData.role,
            };
            setCurrentUser(user);
            remainingTime = calcRemaningTime(expired);
          }
        })
        .catch((err) => {});
    } else {
      logout();
    }

    if (remainingTime !== null) {
      timeout = setTimeout(() => {
        logout();
      }, remainingTime);
    }
    // checkValidUrl();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [currentUser]);

  const calcRemaningTime = (expired) => {
    const now = new Date().getTime();
    const remaning = expired - now;
    if (remaning < 0) return 0;
    return remaning;
  };

  const login = async (username, password, isFirst, complete, onError) => {
    try {
      const data = await authApi.login(username, password, isFirst);
      if (data && data.hasOwnProperty("token")) {
        const { expire_in, token, role } = data;
        const expired = new Date().getTime() + expire_in * 1000;
        setCurrentUser({
          username,
          token,
          expired,
          role,
        });
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(EXPIRED_KEY, expired);
        localStorage.setItem(USERNAME_KEY, username);
        if (role === Role.ADMIN || role === Role.OPERATOR) {
          history.push("/dashboard");
        } else if (role === Role.ACCOUNT) {
          history.push("/user");
        } else {
          history.push("/playback");
        }

        complete();
      } else if (data && data.hasOwnProperty("is_first")) {
        complete(data.is_first, data.username);
      }
    } catch (err) {
      console.log(err);
      complete();
      if (onError) {
        onError(err);
      }
      // showErrorNotification(err?.detail);
    }
  };

  const logout = async () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(EXPIRED_KEY);
    localStorage.removeItem(USERNAME_KEY);
    setCurrentUser(null);

    history.push("/login");
  };

  const value = {
    login,
    logout,
    currentUser,
    loading,
  };

  return (
    <authContext.Provider value={value}>
      {loading ? <Loading /> : <>{children}</>}
      {/* {children} */}
    </authContext.Provider>
  );
}
