import { Button, Checkbox, Col, Dropdown, Row } from "antd";
import { useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";

import styles from "./style.module.css";
import { reportAction } from "store/reportSlice";

const DropdownCheckbox = ({ dataList = [], value, onChange = () => { }, children }) => {
  const dispatch = useDispatch();

  const handleClickFilter = () => {
    dispatch(reportAction.setLoading(true));
    dispatch(reportAction.changeFilterKeywords(value));
    dispatch(reportAction.setLoading(false));
  };

  const menu = (
    <Checkbox.Group
      value={value}
      onChange={onChange}
      className={styles.checkbox_container}
    >
      <Row className={styles.checkbox_row}>
        {dataList.map((data) => {
          return (
            <Col
              span={24}
              key={data.value}
              className={styles.checkbox_col}>
              <Checkbox
                value={data.value}
                className={styles.checkbox_text}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {data.label}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
      <Col
        span={24}
        className={`${styles.button_filter}`}
      >
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={handleClickFilter}
        >
          Lọc
        </Button>
      </Col>
    </Checkbox.Group>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger="click"
    >
      {children}
    </Dropdown>
  );
};

export default DropdownCheckbox;