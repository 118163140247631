import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineProfile } from 'react-icons/ai';

import styles from './style.module.css';
import CustomTable from '../CustomTable';
import { showErrorNotification } from 'components/ui/Notification';
import reportApi from 'apis/report';
import Filter from '../Filter';
import { Button, Tag, Tooltip } from 'antd';
import Heath from 'components/ui/Heath';
import moment from 'moment';
import _, { groupBy } from 'lodash';
import SelectMultipleTagV2 from 'components/ui/SelectMultipleTagV2';
import DropdownCheckbox from '../DropdownCheckbox';

const ListReport = () => {
  const dispatch = useDispatch();

  // const [loading, setLoading] = useState(false);

  const reportStore = useSelector((state) => state.report);

  const loading = reportStore.loading;
  const allStore = reportStore.allStore;
  const allStoreFilter = reportStore.allStoreFilter;
  const stores = reportStore.stores;
  const storesFilter = reportStore.storesFilter;

  // const [overallReportData, setOverallReportData] = useState([]);
  // const [storeSalesReportData, setStoreSalesReportData] = useState([]);

  // const [province, setProvince] = useState("");
  // const [store, setStore] = useState();
  // const [tags, setTags] = useState();
  // const [dateRange, setDateRange] = useState();

  const [keywordExpand, setKeywordExpand] = useState(false);

  const [columnsAllStore, setColumnsAllStore] = useState([]);
  const [columnsStores, setColumnsStores] = useState([]);

  const [keywords, setKeywords] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  const loadReport = useCallback(() => {
    // setLoading(true);
    // getReports(currentPage, currentSize)
    //   .then((resp) => {
    //     setLoading(false);
    //     setOverallReportData(resp);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     showErrorNotification(error.detail);
    //   });
  }, []);

  useEffect(() => {
    setKeywords(reportStore.filterKeywords);
  }, []);

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  useEffect(() => {
    const keywordListFilter = keywordList.filter(
      (keyword) => keyword.value !== 'all'
    );

    const allKeywords = reportStore.filterKeywords
      .map((keyword) => {
        const foundIndex = keywordListFilter.findIndex(
          (item) => item.value === keyword
        );

        return keywordListFilter[foundIndex];
      })
      .filter((keyword) => keyword);

    const allKeywordsFilter = [];

    allKeywords.forEach((keyword) => {
      if (keyword.group) {
        const groupMemberKeywords = keywordListFilter.filter(
          (item) => item.group === keyword.group
        );

        groupMemberKeywords.forEach((item) => {
          allKeywordsFilter.push(item);
        });
      } else {
        allKeywordsFilter.push(keyword);
      }
    });

    if (allKeywordsFilter.length > 0) {
      getColumnKeywords(allKeywordsFilter);
    } else {
      getColumnKeywords(keywordListFilter);
    }
  }, [keywordList, reportStore.filterKeywords]);

  const getColumnKeywords = (allKeywords) => {
    const columns1 = [
      {
        title: '',
        width: '40rem',
        render: () => {
          return <span className={styles.text}>Số đơn hàng / Tỉ lệ % đạt</span>;
        },
      },
      {
        title: 'Số đơn hàng',
        dataIndex: 'so',
        key: 'so',
        width: '20rem',
        render: (so) => {
          return <span className={styles.text}>{so}</span>;
        },
      },
    ];

    const columns2 = [
      {
        title: 'Shop code',
        dataIndex: 'store',
        key: 'store',
        width: '40rem',
        render: (store) => {
          return (
            <Tooltip title={store?.label}>
              <span className={styles.text}>{store?.value}</span>
            </Tooltip>
          );
        },
      },
      {
        title: 'Số đơn hàng',
        dataIndex: 'so',
        key: 'so',
        width: '20rem',
        render: (so) => {
          return <span className={styles.text}>{so}</span>;
        },
      },
    ];

    allKeywords.forEach((item) => {
      columns1.push({
        title: item.label,
        dataIndex: item.label,
        key: item.label,
        width: '20rem',
        render: (row) => {
          const percent = row?.percent || 0;
          const color = percent >= 10 ? 'green' : 'red';

          return (
            <div className="flex-column" style={{ alignItems: 'flex-start' }}>
              <span className={styles.text}>{row?.value || 0}</span>
              <Tag color={color}>{percent}%</Tag>
            </div>
          );
        },
      });
      columns2.push({
        title: item.label,
        dataIndex: item.label,
        key: item.label,
        width: '20rem',
        render: (row) => {
          const percent = row?.percent || 0;
          const color = percent >= 10 ? 'green' : 'red';

          return (
            <div className="flex-column" style={{ alignItems: 'flex-start' }}>
              <span className={styles.text}>{row?.value || 0}</span>
              <Tag color={color}>{percent}%</Tag>
            </div>
          );
        },
      });
    });

    setColumnsAllStore(columns1);
    setColumnsStores(columns2);
  };

  const getAllKeywords = () => {
    const parseStringKeywords = (string) => {
      const values = [];

      let start = 0;
      let end = 0;
      while (end < string.length) {
        if (string[end] === '|') {
          const value = string.slice(start, end);
          values.push(value.replace(/^\||\|$/g, '')); // Loại bỏ dấu "|" đầu và cuối
          start = end + 1;
        }
        end++;
      }

      // Xử lý phần còn lại của chuỗi
      if (start < string.length) {
        const value = string.slice(start);
        values.push(value.replace(/^\||\|$/g, '')); // Loại bỏ dấu "|" đầu và cuối
      }

      // Xử lí loại bỏ "\\\b(" và "(\\b"
      return values.map((value) =>
        value.replace('\\b(', '').replace(')\\b', '')
      );
    };

    reportApi.getAllKeywords().then((response) => {
      let allKeywords = [];

      response.forEach((item) => {
        if (item.label) {
          const valueList = parseStringKeywords(item.value);
          valueList.forEach((value) => {
            allKeywords.push({
              label: value,
              value: value,
              group: item.label,
              parent: value === item.label,
            });
          });
        } else {
          allKeywords.push({
            label: item.value,
            value: item._id,
            parent: true,
          });
        }
      });

      const newAllKeywords = [
        { label: 'Tất cả keywords', value: 'all', parent: true },
        ...allKeywords,
      ];
      setKeywordList(newAllKeywords);
    });
  };

  useEffect(() => {
    getAllKeywords();
  }, [reportStore]);

  return (
    <div className={styles.container}>
      <CustomTable
        tables={[
          {
            datasource: allStoreFilter,
            columns: columnsAllStore,
            loading: loading,
            // rowKey,
            HeaderActionComponent: (
              <div
                className="w-100"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}
                >
                  Tổng hợp chung
                </span>
                <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                  <span
                    style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}
                  >
                    Thiết lập keyword cần báo cáo
                  </span>
                  <div className="flex-center">
                    <DropdownCheckbox
                      value={keywords}
                      onChange={(values) => {
                        console.log('values:', values);

                        if (values.length > 0) {
                          const lastValue = values[values.length - 1];
                          const foundIndexValue = values.findIndex(
                            (value) => value === 'all'
                          );
                          const foundMemberIndexValue = values.findIndex(
                            (value) => value === 'thành viên'
                          );

                          console.log(
                            'found',
                            foundIndexValue,
                            foundMemberIndexValue
                          );

                          if (foundIndexValue === values.length - 1) {
                            setKeywords([lastValue]);
                          } else {
                            // if(foundMemberIndexValue)
                            setKeywords(
                              values.filter((value) => value !== 'all')
                            );
                          }
                        } else {
                          setKeywords(values);
                        }
                      }}
                      dataList={keywordList.filter(
                        (item) => item.parent === true
                      )}
                    >
                      <Tooltip title="Keywords">
                        <AiOutlineProfile
                          className={styles.button_icon_keywords}
                        />
                      </Tooltip>
                    </DropdownCheckbox>
                  </div>
                </div>
              </div>
            ),
          },
          {
            datasource: storesFilter,
            columns: columnsStores,
            loading: loading,
            HeaderActionComponent: (
              <div
                className="w-100"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}
                >
                  Chi tiết thống kê theo từng cửa hàng
                </span>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: 16 }}
                ></div>
              </div>
            ),
          },
        ]}
        title="Thống kê keywords theo đơn hàng"
        desc="Chọn lựa các tiêu chí đề xuất báo cáo keywords theo mã đơn hàng"
        datasource={[]}
        columns={columnsAllStore}
        loading={loading}
        ExtraComponent={
          <div className="flex-column w-100">
            <Filter />
          </div>
        }
        // HeaderActionCompomemt={
        //   <>
        //     <Button
        //       // loading={exporting}
        //       // onClick={handleExportData}
        //       type="primary"
        //     >
        //       Export PDF
        //     </Button>
        //   </>
        // }
      />
    </div>
  );
};

export default ListReport;
