import React from "react";
import { Table, Pagination } from "antd";
import styles from "./style.module.css";

function CustomTable({
  datasource,
  columns,
  title,
  desc,
  total,
  pageObj,
  selectMultipleObj,
  HeaderActionCompomemt,
  loading,
  ExtraComponent,
  rowKey,
  tables = []
}) {
  /* 
    pageObj: {onChangePage, page, pageSize}
    selectMultipleObj: {selectedRowKeys, setSelectRowKeys, MultipleActionComponent}
  */
  let rowSelection = null;
  let hasSelected = false;
  // const hasPaging = pageObj !== null && pageObj !== undefined;

  if (selectMultipleObj !== undefined && selectMultipleObj !== null) {
    const { selectedRowKeys, setSelectedRowKeys, MultipleActionComponent } =
      selectMultipleObj;
    hasSelected = selectedRowKeys && selectedRowKeys.length > 0;
    rowSelection = {
      selectedRowKeys,
      onChange: setSelectedRowKeys,
    };
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.header_top}>
          <div className={styles.header_top_total}>
            <span className={styles.text_title}>{title}</span>
            {total && (
              <span>{`(${total})`}</span>
            )}
          </div>

          <div className={styles.header_action_container}>
            {HeaderActionCompomemt !== null &&
              HeaderActionCompomemt !== undefined &&
              HeaderActionCompomemt}
          </div>
        </div>
        <span className={styles.text_desc}>{desc}</span>

        <div className={styles.multiple_select_action_container}>
          {selectMultipleObj?.MultipleActionComponent}
          {hasSelected && (
            <span className={styles.text_desc}>
              Selected {selectMultipleObj?.selectedRowKeys.length} items
            </span>
          )}
        </div>

        <>
          {ExtraComponent !== undefined &&
            ExtraComponent !== null &&
            ExtraComponent}
        </>
      </div>
      <div className={styles.tables_container}>
        {tables.map((table, index) => {
          return (
            <div
              key={index}
              className={styles.tables_item}
            >
              <div className={styles.tables_header}>
                {table.HeaderActionComponent !== null &&
                  table.HeaderActionComponent !== undefined &&
                  table.HeaderActionComponent}
              </div>
              <Table
                rowKey={table.rowKey}
                // rowSelection={rowSelection}
                dataSource={table.datasource}
                columns={table.columns}
                pagination={false}
                className={styles.table}
                loading={loading}
                rowClassName={(record, index) => {
                  if ((index + 1) % 2 === 0) {
                    return styles.event_row;
                  }
                  return "";
                }}
                scroll={{
                  x: 2000,
                }}
              />
            </div>
          )
        })}
      </div>

      {/* <Table
        rowKey={rowKey}
        rowSelection={rowSelection}
        dataSource={datasource}
        columns={columns}
        pagination={!hasPaging}
        className={styles.table}
        loading={loading}
        rowClassName={(record, index) => {
          if ((index + 1) % 2 === 0) {
            return styles.event_row;
          }
          return "";
        }}
      /> */}

      {/* {hasPaging && (
        <div className={styles.bottom}>
          <Pagination
            current={pageObj?.page}
            total={total}
            pageSize={pageObj?.pageSize}
            onChange={pageObj?.onChangePage}
            size="small"
          />
        </div>
      )} */}
    </div>
  );
}

export default CustomTable;
