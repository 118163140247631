import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import styles from './Filter.module.css';
import SelectMultipleTagV2 from 'components/ui/SelectMultipleTagV2';
import FormControl from 'components/ui/FormControl';
import reportApi from 'apis/report';
import { reportAction } from 'store/reportSlice';

function Filter() {
  const dispatch = useDispatch();
  const reportStore = useSelector((state) => state.report);

  const [provinces, setProvinces] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().subtract(7, 'days'),
    moment(),
  ]);

  // console.log("reportStore:", reportStore);
  // console.log("tags:", tags, tagList);
  // console.log("stores:", stores, storeList);
  // console.log("provinces:", provinces, provinceList);
  // console.log("dateRange:", dateRange);

  useEffect(() => {
    setProvinces(reportStore.filterProvinces);
    setTags(reportStore.filterTags);
    setStores(reportStore.filterStore);

    getAllProvinces();
    getAllTags();
    getAllStores();
  }, [reportStore]);

  const getAllStores = () => {
    reportApi.getAllStores().then((response) => {
      const allStores = response.stores?.map((item) => {
        const label =
          item.name === 'Tất cả' ? item.name : `${item.name} (${item.address})`;

        return {
          label,
          value: item.name,
          _id: item.id,
        };
      });
      const newAllStores = [
        { label: 'Tất cả cửa hàng', value: 'all', name: 'all' },
        ...allStores,
      ];
      setStoreList(newAllStores);
    });
  };

  const getAllTags = () => {
    reportApi.getAllTags().then((response) => {
      setTagList(
        response.tags.map((item) => {
          return {
            value: item,
          };
        })
      );
    });
  };

  const getAllProvinces = () => {
    reportApi.getAllProvinces().then((response) => {
      setProvinceList(
        response.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    });
  };

  const handleClickSearch = () => {
    // dispatch(reportAction.setLoading(true));

    dispatch(reportAction.changeFilterTags(tags));
    dispatch(reportAction.changeFilterStores(stores));
    dispatch(reportAction.changeFilterProvinces(provinces));
    // dispatch(reportAction.changeFilterDate(dateRange));

    reportApi.getReports(stores, dateRange).then((response) => {
      const { allStore, stores } = response;

      const allStoreData = [
        {
          so: allStore.so,
        },
      ];

      const keywords = Object.keys(allStore.keywords);

      keywords.forEach((keyword) => {
        allStoreData[0][keyword.toLowerCase()] = {
          value: allStore.keywords[keyword],
          percent: Math.round((allStore.keywords[keyword] * 100) / allStore.so),
        };
      });

      const storesData = [];
      const keys = Object.keys(stores);
      keys.forEach((key) => {
        const row = {
          shopCode: key,
          store: storeList.find((item) => item.value === key),
          so: stores[key].so,
        };
        const keywords = Object.keys(stores[key].keywords);

        keywords.forEach((keyword) => {
          row[keyword.toLocaleLowerCase()] = {
            value: stores[key].keywords[keyword],
            percent: Math.round(
              (stores[key].keywords[keyword] * 100) / stores[key].so
            ),
          };
        });

        return storesData.push(row);
      });

      dispatch(reportAction.setAllStores(allStoreData));
      dispatch(reportAction.setAllStoresFilter(allStoreData));
      dispatch(reportAction.setStores(storesData));
      dispatch(reportAction.setStoresFilter(storesData));
      dispatch(reportAction.setLoading(false));
    });
  };

  return (
    <div className={styles.container}>
      <FormControl title="Tỉnh/thành phố">
        <SelectMultipleTagV2
          placeholder="Chọn tỉnh/thành phố"
          allTags={provinceList}
          selectTags={provinces}
          setSelectTags={setProvinces}
          allowClear={false}
        />
      </FormControl>

      <FormControl title="Store">
        <SelectMultipleTagV2
          placeholder="Chọn store"
          allTags={storeList}
          selectTags={stores}
          setSelectTags={(values) => {
            if (values.length > 0) {
              const lastValue = values[values.length - 1];
              const foundIndexValue = values.findIndex(
                (value) => value === 'all'
              );

              if (foundIndexValue === values.length - 1) {
                setStores([lastValue]);
              } else {
                setStores(values.filter((value) => value !== 'all'));
              }
            } else {
              setStores(values);
            }
          }}
          allowClear={false}
        />
      </FormControl>

      <FormControl title="Tags">
        <SelectMultipleTagV2
          placeholder="Chọn tag"
          allTags={tagList}
          selectTags={tags}
          setSelectTags={setTags}
          allowClear={false}
        />
      </FormControl>

      <FormControl title="Date">
        <DatePicker.RangePicker
          value={dateRange}
          onChange={setDateRange}
          ranges={{
            'Hôm nay': [moment(), moment()],
            'Hôm qua': [
              moment().subtract(1, 'days'),
              moment().subtract(1, 'days'),
            ],
            '7 ngày trước': [
              moment().subtract(1, 'week'),
              moment().subtract(1, 'week'),
            ],
            '14 ngày trước': [
              moment().subtract(2, 'week'),
              moment().subtract(2, 'week'),
            ],
            '30 ngày trước': [
              moment().subtract(30, 'days'),
              moment().subtract(30, 'days'),
            ],
            'Tuần này': [moment().startOf('week'), moment().endOf('week')],
            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
          }}
          allowClear={false}
        />
      </FormControl>

      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={handleClickSearch}
      >
        Báo cáo
      </Button>
    </div>
  );
}

export default Filter;
