import { configureStore } from "@reduxjs/toolkit";
import monitoringReducer from "./monitoringSlice";
import storeReducer from "./storeSlice";
import userReducer from "./userSlice";
import videoReducer from "./videoSlice";
import syncReducer from "./sync";
import reportReducer from "./reportSlice";

const store = configureStore({
  reducer: {
    monitoring: monitoringReducer,
    store: storeReducer,
    user: userReducer,
    video: videoReducer,
    sync: syncReducer,
    report: reportReducer
  },
});

export default store;
