import _ from "lodash";
import queryString from "query-string";
import moment from "moment";

import axiosClient from "./axiosClient";
import axiosClientV2 from "./axiosClientV2";

const reportApi = {
  getReports: (stores = [], date = []) => {
    const query = {};

    const storeIds = stores.filter(item => item !== "all");

    if (storeIds.length > 0) {
      query.storeId = storeIds.join(",");
    }

    if (_.isArray(date) && date.length === 2) {
      query.fromTimestamp = moment.utc(date[0]).startOf("day").unix();
      query.toTimestamp = moment.utc(date[1]).endOf("day").unix();
    } else {
      query.fromTimestamp = moment.utc().subtract(7, "days").startOf("day").unix();
      query.toTimestamp = moment.utc().endOf("day").unix();
    }

    const url = queryString.stringifyUrl({
      url: "/report/so-keyword",
      query
    });

    return axiosClientV2.get(url);
  },
  getAllProvinces: async () => {
    return new Promise((resolve, reject) => {
      const data = [
        {
          "_id": "60eaaa6f1173335842c35663",
          "name": "An Giang",
          "slug": "an-giang",
          "type": "tinh",
          "name_with_type": "Tỉnh An Giang",
          "code": "89",
        },
        {
          "_id": "60eaaa6f1173335842c3565b",
          "name": "Bà Rịa - Vũng Tàu",
          "slug": "ba-ria---vung-tau",
          "type": "tinh",
          "name_with_type": "Tỉnh Bà Rịa - Vũng Tàu",
          "code": "77",
        },
        {
          "_id": "60eaaa6f1173335842c35668",
          "name": "Bạc Liêu",
          "slug": "bac-lieu",
          "type": "tinh",
          "name_with_type": "Tỉnh Bạc Liêu",
          "code": "95",
        },
        {
          "_id": "60eaaa6f1173335842c35639",
          "name": "Bắc Giang",
          "slug": "bac-giang",
          "type": "tinh",
          "name_with_type": "Tỉnh Bắc Giang",
          "code": "24",
        },
        {
          "_id": "60eaaa6f1173335842c3562e",
          "name": "Bắc Kạn",
          "slug": "bac-kan",
          "type": "tinh",
          "name_with_type": "Tỉnh Bắc Kạn",
          "code": "06",
        },
        {
          "_id": "60eaaa6f1173335842c3563c",
          "name": "Bắc Ninh",
          "slug": "bac-ninh",
          "type": "tinh",
          "name_with_type": "Tỉnh Bắc Ninh",
          "code": "27",
        },
        {
          "_id": "60eaaa6f1173335842c3565f",
          "name": "Bến Tre",
          "slug": "ben-tre",
          "type": "tinh",
          "name_with_type": "Tỉnh Bến Tre",
          "code": "83",
        },
        {
          "_id": "60eaaa6f1173335842c35659",
          "name": "Bình Dương",
          "slug": "binh-duong",
          "type": "tinh",
          "name_with_type": "Tỉnh Bình Dương",
          "code": "74",
        },
        {
          "_id": "60eaaa6f1173335842c3564d",
          "name": "Bình Định",
          "slug": "binh-dinh",
          "type": "tinh",
          "name_with_type": "Tỉnh Bình Định",
          "code": "52",
        }
      ];

      resolve({
        data
      });
    });
  },
  getAllTags: () => {
    const url = "/devices/tags/retrieve";

    return axiosClient.get(url);
  },
  getAllStores: (isBind) => {
    let url = "/stores/";

    if (isBind !== undefined) {
      url += `?is_bind=${isBind}`;
    };

    return axiosClient.get(url);
  },
  getAllKeywords: () => {
    let url = `/keywords/`;

    return axiosClient.get(url);
  }
};

export default reportApi;
