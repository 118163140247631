import React, { useState } from "react";
import { Button, message } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { StatusMap } from "constants/global";
import styles from "./style.module.css";
import deviceMonitoringApi from "apis/deviceMonitoring";

const Color = {
  NEW: "#b28a0d",
  ERR: "#c0392b",
  OFFLINE: "#69707D",
  SUCCESS: "#2ecc71",
};

function Heath({
  status,
  showMessage = true,
  showReason = false,
  mac = null,
  usbPort = null,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  let color = null;
  if (status == -1) {
    color = Color.NEW;
  } else if (status == 0) {
    color = Color.SUCCESS;
  } else if (status == 1) {
    color = Color.ERR;
  } else {
    color = Color.OFFLINE;
  }

  const getLastLog = () => {
    if (!mac || !usbPort) {
      return;
    }
    setLoading(true);
    deviceMonitoringApi
      .getLastLog(mac, usbPort)
      .then((resp) => {
        setLoading(false);
        if (resp && resp.log) {
          console.log(resp.log.message);
          messageApi.open({
            type: "error",
            content: resp.log.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container} style={{ background: color }}></div>
      {showMessage && <span className={styles.text}>{StatusMap[status]}</span>}
      {showReason && mac && usbPort && status === 1 && (
        <>
          {contextHolder}
          {loading ? (
            <Spin indicator={<LoadingOutlined />} />
          ) : (
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              size="small"
              onClick={() => {
                // messageApi.open({
                //   type: "error",
                //   content: "xxxx",
                // });
                getLastLog();
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Heath;
