import axiosClient from "./axiosClient";

const deviceMonitoringApi = {
  getDevices: (page, size, name, tags, store, mac) => {
    let url = `/devices/?page=${page}&size=${size}`;
    const payload = {
      name: name !== undefined && name !== null && name !== "" ? name : null,
      tags:
        tags !== undefined && tags !== null && tags.length > 0 ? tags : null,
      store_id:
        store !== undefined && store !== null && store !== "" ? store : null,
      mac: mac !== undefined && mac !== null && mac !== "" ? mac : null,
    };
    return axiosClient.post(url, payload);
  },
  updateDevice: (devicecId, payload) => {
    const url = `/devices/${devicecId}`;
    return axiosClient.put(url, payload);
  },
  updateMultipleDevices: (payload) => {
    const url = "/devices/edit/multiple";
    return axiosClient.put(url, payload);
  },

  deleteDevice: (devicecId) => {
    const url = `/devices/${devicecId}`;
    return axiosClient.delete(url);
  },
  getLogs: (macAddress, usbPort, startDate, endDate, showDate) => {
    let url = `/devices/mac/${macAddress}/usb/${usbPort}/logs?start=${startDate}&end=${endDate}`;
    if (showDate !== null && showDate !== undefined) {
      url += `&full_date=${showDate}`;
    }
    return axiosClient.get(url);
  },
  countErrorLogs: (macAddress, usbPort, startDate, endDate, errorType) => {
    let url = `/devices/mac/${macAddress}/usb/${usbPort}/count_logs?start=${startDate}&end=${endDate}&error_type=${errorType}`;
    return axiosClient.get(url);
  },
  getUpdateDeviceLogs: (deviceId, startDate, endDate) => {
    const url = `/devices/${deviceId}/update_logs?start=${startDate}&end=${endDate}`;
    return axiosClient.get(url);
  },
  getLastLog: (macAddress, usbPort) => {
    const url = `/devices/mac/${macAddress}/usb/${usbPort}/last_logs`;
    return axiosClient.get(url);
  },
  getUpgradeLogs: (mac, startDate, endDate) => {
    const url = `/devices/mac/${mac}/upgrade_logs?start=${startDate}&end=${endDate}`;
    return axiosClient.get(url);
  },
  uploadImage: (deviceId, image) => {
    const url = `/devices/${deviceId}/upload`;
    const formData = new FormData();
    formData.append("uploaded_file", image);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axiosClient.post(url, formData, config);
  },

  getDeviceByStore: (storeId) => {
    const url = `/devices/store/${storeId}`;
    return axiosClient.get(url);
  },

  sendRequestToClient: (payload) => {
    const url = "/recording/request_client";
    return axiosClient.post(url, payload);
  },

  getAllTags: () => {
    const url = "/devices/tags/retrieve";
    return axiosClient.get(url);
  },

  addMultipleTags: (tags, deviceIds) => {
    const url = "/devices/tags/add";
    const payload = {
      tags,
      device_ids: deviceIds,
    };
    return axiosClient.post(url, payload);
  },
  removeMultipleTags: (tags, deviceIds) => {
    const url = "/devices/tags/remove";
    const payload = {
      tags,
      device_ids: deviceIds,
    };
    return axiosClient.post(url, payload);
  },
  getRequestUpdateChannel: (mac, usbPort) => {
    const url = `/devices/channel/request_change?mac=${mac}&usb_port=${usbPort}`;
    return axiosClient.get(url);
  },
};

export default deviceMonitoringApi;
