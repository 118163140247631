import { Role } from 'constants/global';
const baseMenu = {
	report: { path: '/report', label: 'Business Report' },
	playback: { path: '/playback', label: 'Playback' },
	dashboard: { path: '/dashboard', label: 'System Monitor' },
	store: { path: '/store', label: 'Store Management' },
	user: { path: '/user', label: 'User Management' },
	dictionary: { path: '/dictionary', label: 'Dictionary' },
	transcriptTasks: { path: '/transcript-tasks', label: 'Transcript Tasks' },
	keywords: { path: '/keywords', label: 'Keywords' },
};

export const navMenu = {
	[Role.ADMIN]: [
		baseMenu.report,
		baseMenu.playback,
		baseMenu.dashboard,
		baseMenu.store,
		baseMenu.user,
		baseMenu.dictionary,
		baseMenu.transcriptTasks,
		baseMenu.keywords,
	],
	[Role.OPERATOR]: [
		baseMenu.report,
		baseMenu.playback,
		baseMenu.dashboard,
		baseMenu.store,
		baseMenu.transcriptTasks,
		baseMenu.keywords,
	],
	[Role.QA]: [baseMenu.report, baseMenu.playback, baseMenu.transcriptTasks],
	[Role.STORE]: [baseMenu.playback],
	[Role.ACCOUNT]: [baseMenu.report, baseMenu.user],
};
