import React from 'react';
import { Select, Tag } from 'antd';
import styles from './style.module.css';

function CustomTag(props) {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color="blue"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}
    >
      {label}
    </Tag>
  );
}

function SelectMultipleTagV2({
  allTags,
  selectTags,
  setSelectTags,
  style,
  placeholder,
  allowClear = false,
}) {
  return (
    <Select
      id="name"
      allowClear={allowClear}
      disabled={allTags.length === 0}
      mode="multiple"
      value={selectTags}
      onChange={setSelectTags}
      placeholder={placeholder || 'Select tags'}
      showArrow
      tagRender={CustomTag}
      maxTagTextLength={20}
      maxTagCount="responsive"
      style={
        style
          ? style
          : {
              width: '100%',
            }
      }
      options={allTags}
      className="text-medium-12"
    />
  );
}

export default SelectMultipleTagV2;
